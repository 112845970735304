import React from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { motion } from "framer-motion";
import waimg from "../../assets/images/whatsapp.png";
import blogo from "../../assets/images/bilcono.jpg";

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Navbar />

      <main className="flex-grow flex flex-col items-center px-6 py-10">
        {/* Header */}
        {/* <img
          src={blogo}
          alt="Bilcono Logo"
          className="w-40 h-auto mb-4 shadow-md rounded-lg transform transition duration-300 hover:scale-110"
        /> */}
        <h1 className="text-4xl font-bold text-center mb-8 text-gray-800 md:text-5xl">
          About Bilcono – A Complete Solution for NGO Procurement
        </h1>

        {/* Introduction */}
        <motion.p
          className="text-lg text-center max-w-3xl mb-6 text-gray-600 md:text-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Bilcono provides a platform that helps NGOs connect with verified
          suppliers and manage procurement easily. Our system offers real-time
          tracking, ensuring transparency, efficiency, and accountability. By
          bringing together active tenders and NGO tenders, we make it simple
          for organizations to find the right suppliers and for donors to follow
          the progress of projects. Start managing your procurement needs with
          ease—sign up with Bilcono now!
        </motion.p>

        {/* Section: Who We Are */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Who We Are
            </h2>
            <p className="text-lg text-center text-gray-600">
              At Bilcono, we’re reshaping the development sector with innovative
              digital tools to simplify procurement. Our platform brings
              together NGO tenders and active tenders in one place, making it
              easier for NGOs, suppliers, and donors to collaborate effectively.
              Whether you're looking for active NGO tenders or tracking project
              progress, we help ensure transparency, accountability, and
              efficiency throughout the procurement process. Bilcono is your
              trusted partner for managing NGO tenders in Pakistan and beyond,
              helping you connect with the right suppliers and stakeholders.
            </p>
          </div>
        </motion.div>

        {/* Section: Our Mission */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Our Mission
            </h2>
            <p className="text-lg text-center text-gray-600">
              Our mission is simple yet impactful: to simplify and enhance the
              procurement process for NGOs, suppliers, and donors in Pakistan
              and beyond. By centralizing tenders, offering real-time tracking,
              and ensuring verified connections, we streamline procurement
              processes to make them smooth, efficient, and transparent for all
              stakeholders. Our platform is tailored to meet the unique
              challenges faced by NGOs in Pakistan, ensuring they can connect
              with reliable suppliers and maintain accountability to donors.
              With Bilcono, managing procurement is no longer a hassle—it’s a
              seamless experience designed for the development sector.
            </p>
          </div>
        </motion.div>

        {/* Section: A Complete Solution */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              A Complete Solution for All Stakeholders
            </h2>
            <div className="text-lg text-gray-600 space-y-4">
              <p>
                <strong>For Suppliers:</strong> Access active tenders from all
                NGOs on a single, user-friendly platform, helping you find
                opportunities and grow your business in the development sector.
              </p>
              <p>
                <strong>For NGOs:</strong> Discover and connect with a diverse
                network of verified suppliers, ensuring smooth and reliable
                procurement for all your NGO
              </p>
              <p>
                <strong>For Donors:</strong> Monitor the entire procurement
                process in real time with full transparency, ensuring resources
                are utilized responsibly and efficiently.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Section: Key Features */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Key Features of the NGO Tenders Portal
            </h2>
            <ul className="text-lg list-disc list-inside text-gray-600 space-y-3">
              <p>
                {" "}
                The NGO Tenders Portal by Bilcono is designed to simplify and
                enhance procurement processes for NGOs, suppliers, and donors.
                Here are the key features that make it the go-to platform for
                NGO tenders Pakistan:{" "}
              </p>

              <li>
                <strong>Tender Consolidation:</strong> Access all active tenders
                from various NGOs in one centralized location, saving time and
                effort.
              </li>
              <li>
                <strong>Supplier Directory:</strong> A comprehensive database of
                verified suppliers to ensure reliable procurement solutions.
              </li>
              <li>
                <strong>Donor Monitoring:</strong> Real-time tracking tools to
                help donors monitor procurement activities and maintain
                accountability.
              </li>
              <li>
                <strong>Market Rates:</strong> Stay updated with current market
                rates to make informed and cost-effective procurement decisions.
              </li>
              <li>
                <strong>IFRC Specifications:</strong> Compliance with
                International Federation of Red Cross standards for quality
                assurance in procurement.
              </li>
              <li>
                <strong>Transparent Processes:</strong> Built-in transparency
                measures to promote accountability and trust among stakeholders.
              </li>

              <p>
                {" "}
                Whether you're an NGO, a supplier, or a donor, our platform
                ensures seamless collaboration, efficiency, and transparency in
                managing NGO tenders. Start optimizing your procurement journey
                with Bilcono today!
              </p>
            </ul>
          </div>
        </motion.div>
        {/* Why Choose Us */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Why Choose Us ?
            </h2>
            <p>
              <strong>At Bilcono</strong>, we offer a comprehensive solution for
              managing NGO tenders in Pakistan, ensuring transparency,
              accountability, and efficiency at every step. Our platform brings
              together NGOs, suppliers, and donors to create a seamless
              procurement experience. With features like real-time monitoring,
              consolidated tender access, and verified supplier directories, we
              simplify the complexities of procurement. Whether you're searching
              for active NGO tenders in Pakistan or tracking procurement
              progress, Bilcono ensures a reliable and efficient process, making
              it easier to achieve your development goals.
            </p>
          </div>
        </motion.div>
        {/* Additional Services  */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Additional Services
            </h2>
            <p>
              Bilcono also provides the NGOsJobs Portal, a specialized platform
              designed to connect NGOs with the best talent in the development
              sector. NGOs can post job opportunities while candidates explore
              career openings tailored to the sector’s unique needs. From
              simplifying recruitment to promoting sector-wide growth, our
              platform enhances your hiring process. Combined with our expertise
              in managing NGO procurement and tenders in Pakistan, Bilcono
              ensures your organization has everything it needs to succeed.
            </p>
          </div>
        </motion.div>
        {/* Section: Contact Information */}
        <motion.div
          className="w-full max-w-full mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-all duration-300">
            <h2 className="text-2xl font-semibold text-center mt-4 mb-4 text-gray-700">
              Contact Us
            </h2>

            <div className="text-lg text-gray-600 space-y-2">
              <p>
                Ready to simplify your procurement process? Get started with
                Bilcono today! Whether you're an NGO, a supplier, or a donor,
                our platform has the tools you need to achieve your goals with
                efficiency and transparency. Let’s build a transparent and
                collaborative future—reach out to us now!
              </p>
              <p>
                <strong>For Sales:</strong> sales@ngostenders.com
              </p>
              <p>
                <strong>For Support:</strong> support@ngostenders.com
              </p>
              <p>
                <strong>24/7 Support:</strong> +92 312 1111 002
              </p>
            </div>
          </div>
        </motion.div>
      </main>

      <Footer />

      {/* WhatsApp Floating Button */}
      <a
        href="https://wa.me/+923121111002"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-4 right-4 bg-green-500 rounded-full p-3 shadow-lg transform hover:scale-105 transition-transform"
      >
        <img src={waimg} alt="Chat with us" className="w-8 h-8" />
      </a>
    </div>
  );
};

export default AboutUs;
